<template>
  <div class="accesses-list">

    <div class="options-labels"
         v-if="showOptionsLabel">

      <transition-group name="fade"
                        :css="false"
                        @before-enter="beforeEnterOption"
                        @enter="enterOption"
                        @leave="leaveOption">

        <div class="option-label"
             v-for="(option, option_index) in access.options"
             v-show="!option.hasOwnProperty('parent') || selected.access.indexOf(option.parent.id) !== -1"
             :key="`option-${option_index}`"
             :data-index="option_index">

          {{ option.name }}

        </div>

      </transition-group>

    </div>

    <div class="access-parent">

      <div class="inline-block cursor-pointer collapse-icon"
           v-if="access.children.length"
           @click="show = !show">
        <font-awesome-icon :icon="show ? 'angle-down' : 'angle-left'"/>
      </div>

      <div class="inline-block" :class="access.children.length === 0 ? 'm-r' : ''">

        <vs-checkbox v-model="childSelectAll"
                     @change="!childSelectAll ? selected.access = [] : selected.access = getAllOptions,updateData"
                     :vs-value="true">
          {{ access.title }}
        </vs-checkbox>
      </div>

      <div class="options-checkbox inline-block">

        <transition-group name="fade"
                          :css="false"
                          @before-enter="beforeEnterOption"
                          @enter="enterOption"
                          @leave="leaveOption">

          <div class="option-checkbox"
               v-for="(option, option_index) in access.options"
               v-show="!option.hasOwnProperty('parent') || selected.access.indexOf(option.parent.id) !== -1"
               :key="`access-option-${option_index}`"
               :data-index="option_index">

            <access-tree-check-box class="mx-auto"
                                   v-model="selected.access"
                                   @select:children="selected.children[option.value] = $event"
                                   @change="updateData(!option.hasOwnProperty('parent') ? option.id : '')"
                                   :value="option"/>

            <!--<vs-checkbox class="mx-auto"
                         v-model="selected.access"
                         @change="updateData(!option.hasOwnProperty('parent') ? option.value : '')"
                         :vs-value="option.value">
            </vs-checkbox>-->

          </div>

        </transition-group>

      </div>
    </div>

    <transition-group name="fade"
                      :css="false"
                      @before-enter="beforeEnterRow"
                      @enter="enterRow"
                      @leave="leaveRow">
      <div class="access-children"
           v-for="(child, child_index) in access.children"
           :key="`access-child-${child_index}`"
           :data-index="child_index"
           v-show="show">

        <accesses-tree :access="child"
                       :select-all="childSelectAll ? getAllOptions : selected.access ? selected.access : []"
                       v-model="selected.children[child.field]"/>
      </div>
    </transition-group>
  </div>
</template>

<script>
import gsap from 'gsap'
import AccessTreeCheckBox from '@/components/accessesTree/accessTreeCheckBox'

export default {
  name: 'accessesTree',
  components: {AccessTreeCheckBox},
  model: {
    prop: 'selectedItems',
    event: 'select'
  },
  props: {
    showOptionsLabel: {
      type: Boolean,
      default: false
    },
    selectAll: {},
    access: {
      type: Object,
      default: () => { return [] }
    },
    selectedItems: {}
  },
  computed: {
    getAllOptions () {
      const options = []

      if (this.access) {
        this.access.options.forEach((option => {
          options.push(option.id)
        }))
      }

      return options
    }
  },
  data () {
    return {
      show: false,
      selectCRUD: false,
      childSelectAll: false,
      selected: {
        access: [],
        children: {}
      }
    }
  },
  mounted () {
    // console.log(this.selectedItems)
    if (this.selectedItems && this.selectedItems.access) {
      this.selected = this.selectedItems
    }
  },
  methods: {
    updateData (parent) {
      if (parent && this.selected.access.indexOf(parent) === -1) this.selected.access = []

      this.$emit('select', this.selected)
    },
    beforeEnterRow (el) {
      el.style.opacity = 0
      el.style.height = 0
      el.style.transform = 'translate(30px, 0px)'
    },
    enterRow (el, done) {
      gsap.to(el, {
        opacity: 1,
        height: '35px',
        x: '0',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    },
    leaveRow (el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        x: '30px',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    },
    beforeEnterOption (el) {
      el.style.opacity = 0
      el.style.transform = 'translate(30px, 0px)'
    },
    enterOption (el, done) {
      gsap.to(el, {
        opacity: 1,
        x: '0',
        delay: el.dataset.index * 0.10,
        onComplete: done
      })
    },
    leaveOption (el, done) {
      gsap.to(el, {
        opacity: 0,
        x: '30px',
        delay: el.dataset.index * 0.10,
        onComplete: done
      })
    }
  },
  watch: {
    selectAll: {
      handler (val) {
        this.childSelectAll = val.length === this.access.options.length
        this.selected.access = val
      }
    },
    selectedItems: {
      handler (val) {
        this.selected = val
      }
    },
    selected: {
      handler () {
        this.childSelectAll = this.selected.access.length >= this.access.options.length

        this.updateData()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">

.options-labels {
  width: 260px;
  margin: 0 0 0 auto;

  .option-label {
    display: inline-flex;
    width: 65px;
    text-align: center;
    flex-direction: column;
  }
}

.options-checkbox {
  width: 260px;
  float: right;

  .option-checkbox {
    display: inline-flex;
    width: 65px;
    vertical-align: middle;
  }

  .checkbox_x.vs-checkbox {
    margin: 0 !important;
  }
}

.accesses-list {

  .access-parent {
    height: 20px;
    line-height: 20px;
    overflow: hidden;

    .collapse-icon {
      font-size: 20px;
      width: 12.5px;
    }

    .m-r {
      margin-left: 12.5px;
    }
  }

  .access-children {
    padding-left: 25px;
    //margin: 10px 0;
  }
}

</style>
