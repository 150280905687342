<template>
  <div class="access-tree-checkbox">
    <vs-checkbox class="mx-auto inline-block"
                 @change="$emit('change', $event)"
                 v-model="selectedList"
                 :vs-value="value.id">
    </vs-checkbox>

    <vs-icon v-if="value.children && value.children.length > 0"
             @click="childrenPrompt = true"
             class="inline-block"
             icon="icon-more-vertical"
             icon-pack="feather"/>

    <!-- children prompt -->

    <vs-prompt
      class="p-0"
      color="danger"
      :buttons-hidden="true"
      :title="$t('accessTreeCheckBox.labels.chooseCategory')"
      :active.sync="childrenPrompt">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('accessTreeCheckBox.labels.chooseCategory') }}
          </vs-col>

          <vs-spacer/>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <vs-row v-for="(child, child_index) in value.children"
                :key="child_index">
          <vs-col>
            <vs-checkbox v-model="children" :vs-value="child.id">{{ $t(child.i18n) || child.name }}</vs-checkbox>
          </vs-col>
        </vs-row>
      </div>

    </vs-prompt>

    <!-- /children prompt -->
  </div>
</template>

<script>
export default {
  name: 'accessTreeCheckBox',
  data () {
    return {
      selectedList: [],
      childrenPrompt: false,
      children: []
    }
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Array,
      default: () => { return [] }
    },
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  mounted () {
    if (this.selected && this.selected.length > 0) this.selectedList = this.selected
  },
  methods: {
    updateData (event) {
      /*if (this.selectedList.indexOf(event) !== -1) {
        this.value.children.forEach((child) => {
          this.children.push(child.id)
        })
      } else {
        this.children = []
      }*/

      this.$emit('select:children', this.children)
      this.$emit('change', event)
    }
  },
  watch: {
    selected: {
      handler (val) {
        this.selectedList = val

        /*if (this.value.children) {
          if (this.selectedList.indexOf(this.value.value) !== -1) {
            if (this.children.length === 0) {
              let children_list = []
              this.value.children.forEach((child) => {
                children_list.push(child.value)
              })
              this.children = children_list
            }
          } else {
            this.children = []
          }
        }*/
      },
      deep: true
    },
    children: {
      handler () {
        this.$emit('select:children', this.children)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.access-tree-checkbox {
  i {
    vertical-align: text-top;
    cursor: pointer;
  }
}
</style>
