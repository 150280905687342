<template>
  <vs-prompt
    v-if="eventData && eventData.event"
    class="prompt"
    :buttons-hidden="true"
    title=""
    @close="showEventPromptStatus = true"
    :active.sync="showEventPromptStatus">

    <keep-alive>
      <new-version-event v-if="eventData.event.type === 1"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <log-event v-else-if="eventData.event.type === 2"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <message-event v-else-if="eventData.event.type === 3"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <warning-event v-else-if="eventData.event.type === 4"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <report-event v-else-if="eventData.event.type === 5"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData, true)"/>
    </keep-alive>
  </vs-prompt>
</template>

<script>
import {seenEvent} from '../../http/requests/events'
import NewVersionEvent from './newVersionEvent'
import LogEvent from './logEvent'
import WarningEvent from './warningEvent'
import MessageEvent from './messageEvent'
import ReportEvent from "./reportEvent";

export default {
  name: 'customEvent',
  components: {ReportEvent, MessageEvent, WarningEvent, LogEvent, NewVersionEvent},
  props: {
    eventData: {},
    seenEventStatus: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showEventPromptStatus: false
    }
  },
  methods: {
    showEvent () {
      this.showEventPromptStatus = true
    },
    seenEvent (event, confirm = false) {
      if (this.seenEventStatus) seenEvent(event.event.id, event.id)
      this.$emit('seen')
      if (this.eventData.event.type === 5 && confirm) {
        setTimeout(() => {
          this.$router.push({ name: 'reportDetail', params: {id: event.event.resource_id} })
        }, 100)
      }
    }
  }
}
</script>

<style scoped>

</style>
