<template>
  <div class="image-preview"
       v-if="Object.keys(image).length">

    <lazy-image :src="image.src"
                :alt="image.alt || ''"/>

    <div class="image-preview-overlay"
         @click="$emit('closed')"/>

    <div class="times-icon"
         @click="$emit('closed')">

      <custom-icon icon="TIMES"
                   color="danger"/>
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/lazyImage/lazyImage'
import CustomIcon from '@/components/customIcon/customIcon'
export default {
  name: 'imagePreview',
  components: {
    CustomIcon,
    LazyImage},
  props: {
    image: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style lang="scss">
.image-preview {
  background: #00000088;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 50px;
  z-index: 100000;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 101000;
    max-width: 80vh;
    max-height: 80vh;
    object-fit: contain;
  }

  .times-icon {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .image-preview-overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
</style>
