<template>
  <div class="custom-dialog">
    <vs-prompt
      class="p-0"
      color="danger"
      :buttons-hidden="true"
      :title="title"
      @close="closed ? $emit('close') : promptStatus = true"
      :active.sync="promptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{title}}
          </vs-col>

          <vs-spacer/>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <slot>
          <vs-col class="my-5">
            <span>
            {{ body }}
            </span>
          </vs-col>
        </slot>

        <!--<vs-divider v-if="showButtons"
                    class="my-3"/>-->

        <vs-col v-if="showButtons">
          <vs-col v-if="hasCancel" class="w-1/2 pl-1 text-right useral-font-weight-medium text-danger cursor-pointer">
            <vs-button class="w-full"
                       type="border"
                       color="rgb(153,153,153)"
                       @click="$emit('close'), promptStatus = false">
              {{ $t('customDialog.cancel') }}
            </vs-button>
          </vs-col>

          <vs-col class="pr-1 useral-font-weight-medium text-success cursor-pointer" :class="{'w-1/2': hasCancel}">
            <vs-button class="w-full"
                       :color="accessColor"
                       @click="$emit('accept'), promptStatus = false">
              {{ $t('customDialog.confirm') }}
            </vs-button>
          </vs-col>
        </vs-col>
      </div>

    </vs-prompt>
  </div>
</template>

<script>
export default {
  name: 'customDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    closed: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    hasCancel: {
      type: Boolean,
      default: true
    },
    accessColor: {
      type: String,
      default: 'danger'
    }
  },
  data () {
    return {
      promptStatus: false
    }
  },
  methods: {
    showDialog () {
      this.promptStatus = true
    }
  }
}
</script>

<style scoped>

</style>
