export default {
  regex: {
    time: /^((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,
    date: /^\d{4}[\/](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
    dateTime: /^\d{4}[\/](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[ ]((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,

    utf8: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,

    OTPCode: /^[0-9۰-۹٠-٩]{5}$/,

    name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,

    // user
    user: {
      name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z0-9]+)$/,
      lastName: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,
      phoneNumber: /^09[0-9۰-۹٠-٩]{9}$/,
      phoneNumberStartWith: '09',
      email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      addressName: /^[ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC]{3,}$/,
      address: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z]{5,})$/,
      postCode: /^$|^[0-9۰-۹٠-٩]{10}$/,
    },

    coaches: {
      receive: {
        transferId: /^[0-9_\/-]{4,70}$/,
      }
    },

    // character
    character: {
      name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z0-9]+)$/
    },

    // refund
    refund: {
      name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z0-9]+)$/,
      percent: /^([0-9۰-۹٠-٩]{1,2}|100|0)$/
    },

    //message
    message: {
      key: /^([A-Z0-9]+)$/,
      template: /^([a-zA-Z0-9]+)$/,
    },

    // sale
    sale: {
      addon: {
        name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z0-9]+)$/,
      },

      discount: {
        percent: /^([0-9۰-۹٠-٩]{1,2}|100|0)$/,
        price: /^([0-9۰-۹٠-٩]+)$/,
      }
    },

    // treasury
    treasury: {
      bank: {
        shabaNumber: /^\d{24}$/,
        accountNumber: /^\d{6,15}$/
      },

      paymentGateway: {
        name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ()0-9]{5,50}$/,
        transferId: /^[0-9_\/-]{4,70}$/,
      },

      receive: {
        transactionId: /^[A-Za-z0-9_-]{5,70}$/
      }
    },

    //event
    event: {
      subject: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC \-\ (.)0-9]{5,50}$/,
      content: /^[#_*\[\],،:\>\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC\u0626 a-zA-Z \-\ \r\n(.)0-9]{5,400}$/,
    },

    // access group
    accessGroup: {
      name: /^[\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC ]{3,}$/,
    },

    // setting
    setting: {
      address: {
        name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z]{5,})$/,
      },

      employee: {
        name: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z0-9]+)$/,
        nationalCode: /^\d{10}$/
      },

      tell: {
        whatsApp: /^989[0-9]{9}$/,
      }
    },

    // club info
    company: {
      address: /^([^<>?!@#$%^&*?؟":{}|=<>a-zA-Z]{5,})$/,
    },

    customEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    shaba: {
      prefix: 'IR',
      pattern: /IR[0-9]{24}/
    },

    address: {
      name: /^[ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC]{3,}$/,
      address: /^[/ \u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC\.\-\s\,0-9۰-۹٠-٩]{3,}$/,
      plaque: /^[0-9۰-۹٠-٩]{1,}$/,
      postalCode: /^[0-9۰-۹٠-٩]{10}$/,
    }

  },

  moment: {
    year: 'YYYY',
    month: 'MM',
    yearMonth: 'YY/MM',
    second: 'ss',
    time: 'HH:mm',
    date: 'jYYYY/jMM/jDD',
    dateHour: 'jYYYY/jM/jD HH',
    dateTime: 'jYYYY/jMM/jDD HH:mm',
    dateWithWeek: 'dddd jD jMMMM',
    fullDateWithWeek: 'dddd jD jMMMM jYYYY',
    fullDateTime: 'YYYY-M-D HH:mm:ss',

    weekDay: {
      1: 'شنبه',
      2: 'یک شنبه',
      3: 'دو شنبه',
      4: 'سه شنبه',
      5: 'چهار شنبه',
      6: 'پنج شنبه',
      7: 'جمعه'
    }
  }
}
